import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {setDisplayName, wrapDisplayName} from 'recompose';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import MobileDetect from 'mobile-detect';
import config from '../config';
import * as mediaEntitlementActions from './mediaEntitlementActions';
import * as authActions from '../auth/authActions';
import {mediaRouteResolver} from '../services';
import {withMediaRouteHelpers, withMediaRouteHelpersPropTypes, withMediaRouteHelpersDefaultProps} from '../media/withMediaRouteHelpers';
import withPopUp from '../ui-elements/pop-up/withPopUp';
import DownloadMobileAppPopUp from '../ui-elements/pop-up/pop-up-content/DownloadMobileAppPopUp';
import DisablePlaybackContent from '../ui-elements/pop-up/pop-up-content/DisablePlaybackContent';
import LayoutBox from '../ui-elements/layout-box/LayoutBox';

// TODO - move to config
const GOOGLE_PLAY_STORE_APP_URL = '';
const APPLE_STORE_APP_URL = '';

export const withMediaActions = BaseComponent => {
    @withRouter
    @withPopUp
    @withMediaRouteHelpers({mediaRouteResolver})
    @setDisplayName(wrapDisplayName(BaseComponent, 'withMediaActions'))
    class ComposedComponent extends Component {
        static propTypes = {
            ...BaseComponent.propTypes,
            ...withMediaRouteHelpersPropTypes,
            providers: PropTypes.object,
            isUserSignedIn: PropTypes.bool,
            signInUser: PropTypes.func.isRequired,
            userAccount: PropTypes.object,
            isFastCheckoutInProgress: PropTypes.bool,
            checkoutSubscription: PropTypes.func.isRequired,
            fastCheckoutMediaItemOffer: PropTypes.func.isRequired,
            basket: PropTypes.object,
            currentEntitlementsByMediaItem: PropTypes.object,
            startMediaItemOfferPlayback: PropTypes.func.isRequired,
            setPopUp: PropTypes.func,
            closePopUp: PropTypes.func,
            history: PropTypes.object,
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
            ...withMediaRouteHelpersDefaultProps,
            providers: null,
            isUserSignedIn: false,
            userAccount: null,
            setPopUp: null,
            closePopUp: null,
            history: null,
        };

        checkoutSubscription = offer => {
            const {isUserSignedIn, signInUser} = this.props;
            const {resolveMediaItemRoute} = this.props;
            if (!isUserSignedIn) {
                signInUser({
                    newUserSessionRoute: resolveMediaItemRoute({mediaItemId: offer.mediaItemId}),
                });
                return;
            }

            const {checkoutSubscription} = this.props;
            checkoutSubscription({offer});
        };

        fastCheckoutMediaItemOffer = offer => {
            const {isUserSignedIn, signInUser} = this.props;
            const {resolveMediaItemRoute} = this.props;
            if (!isUserSignedIn) {
                signInUser({
                    newUserSessionRoute: resolveMediaItemRoute({mediaItemId: offer.mediaItemId}),
                });
                return;
            }

            const {fastCheckoutMediaItemOffer} = this.props;
            fastCheckoutMediaItemOffer({offer});
        };

        startMediaItemPreviewPlayback = offer => {
            const {startMediaItemOfferPlayback} = this.props;
            if (!offer) return;

            startMediaItemOfferPlayback({offer});
        };

        startMediaItemOfferPlayback = offer => {
            const {startMediaItemOfferPlayback, isUserSignedIn} = this.props;
            const {closePopUp, setPopUp} = this.props;
            if (!isUserSignedIn || !offer) return;

            // call link redirection offer "playback"
            if (!offer.assetId && offer.link) {
                this.openThirdPartyOfferPlayback(offer);
                return;
            }

            // disable playback it is defined in config
            if (config.TEMP_DISABLE_PLAYBACK) {
                setPopUp(
                    <LayoutBox>
                        <DisablePlaybackContent closePopUp={closePopUp} />
                    </LayoutBox>
                );
                return;
            }

            // show pop up with native app download option if user on mobile
            const md = new MobileDetect(window.navigator.userAgent);
            if (md.os() === 'AndroidOS' || md.os() === 'iOS') {
                const appURI = md.os() === 'AndroidOS' ? GOOGLE_PLAY_STORE_APP_URL : APPLE_STORE_APP_URL;
                setPopUp(
                    <LayoutBox>
                        <DownloadMobileAppPopUp
                            closePopUp={closePopUp}
                            appURI={appURI}
                            content={md.os() === 'AndroidOS' ? 'Android' : 'IOS'}
                        />
                    </LayoutBox>
                );
                return;
            }

            startMediaItemOfferPlayback({offer});
        };

        openThirdPartyOfferPlayback = offer => {
            const windowInstance = window.open(offer.link, '_blank');
            windowInstance.focus();
        };

        openMediaItemRoute = mediaItem => {
            const {resolveMediaItemRoute, history} = this.props;
            history.push(resolveMediaItemRoute({mediaItemId: mediaItem.id}));
        };

        render() {
            return (
                <BaseComponent
                    {...this.props}
                    checkoutSubscription={this.checkoutSubscription}
                    fastCheckoutMediaItemOffer={this.fastCheckoutMediaItemOffer}
                    startMediaItemPreviewPlayback={this.startMediaItemPreviewPlayback}
                    startMediaItemOfferPlayback={this.startMediaItemOfferPlayback}
                    openMediaItemRoute={this.openMediaItemRoute}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        providers: state.storeConfiguration.providers,
        isUserSignedIn: state.auth.isUserSignedIn,
        userAccount: state.auth.userAccount,
        isFastCheckoutInProgress: state.mediaEntitlement.isFastCheckoutInProgress,
        basket: state.mediaEntitlement.basket,
        currentEntitlementsByMediaItem: state.mediaEntitlement.currentEntitlementsByMediaItem,
    });

    const mapDispatchToProps = dispatch => ({
        signInUser: payload => dispatch(
            authActions.signInUser(payload),
        ),
        checkoutSubscription: payload => dispatch(
            mediaEntitlementActions.checkoutSubscription(payload),
        ),
        fastCheckoutMediaItemOffer: payload => dispatch(
            mediaEntitlementActions.fastCheckoutMediaItemOffer(payload),
        ),
        startMediaItemOfferPlayback: payload => dispatch(
            mediaEntitlementActions.startMediaItemOfferPlayback(payload)
        ),
        startMediaItemSVODOfferPlayback: payload => dispatch(
            mediaEntitlementActions.startMediaItemSVODOfferPlayback(payload)
        ),
    });

    return connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ComposedComponent);
};

export const withMediaActionsPropTypes = {
    providers: PropTypes.object,
    isUserSignedIn: PropTypes.bool,
    signInUser: PropTypes.func.isRequired,
    userAccount: PropTypes.object,
    isFastCheckoutInProgress: PropTypes.bool,
    fastCheckoutMediaItemOffer: PropTypes.func.isRequired,
    checkoutSubscription: PropTypes.func.isRequired,
    basket: PropTypes.object,
    currentEntitlementsByMediaItem: PropTypes.object,
    startMediaItemPreviewPlayback: PropTypes.func.isRequired,
    startMediaItemOfferPlayback: PropTypes.func.isRequired,
    openMediaItemRoute: PropTypes.func.isRequired,
    setPopUp: PropTypes.func,
    closePopUp: PropTypes.func,
};

export const withMediaActionsDefaultProps = {
    providers: null,
    isUserSignedIn: false,
    signInUser: () => {},
    userAccount: null,
    isFastCheckoutInProgress: false,
    fastCheckoutMediaItemOffer: () => {},
    checkoutSubscription: () => {},
    startMediaItemPreviewPlayback: () => {},
    startMediaItemOfferPlayback: () => {},
    openMediaItemRoute: () => {},
    setPopUp: null,
    closePopUp: null,
};
