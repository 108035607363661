import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {mediaItemClassification, mediaItemOfferType} from '@comrock/vub-wls-media-service';
import {
    withMediaActions,
    withMediaActionsPropTypes,
    withMediaActionsDefaultProps,
} from '../media-entitlement/withMediaActions';
import './CTAButtonBarView.scss';
import CTAButton from '../ui-elements/cta-button/CTAButton';

@withMediaActions
class CTAButtonBarView extends Component {
    static propTypes = {
        ...withMediaActionsPropTypes,
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        ...withMediaActionsDefaultProps,
        mediaItem: null,
    };

    render() {
        const {isUserSignedIn, userAccount} = this.props;
        const {mediaItem, currentEntitlementsByMediaItem, providers} = this.props;
        const {isFastCheckoutInProgress, fastCheckoutMediaItemOffer, checkoutSubscription} = this.props;
        const {startMediaItemOfferPlayback} = this.props;
        const hasTVODOffers = !!mediaItem.offers.find(offer => offer.offerType === mediaItemOfferType.TVOD);

        // display CTA button bar only for movies and episodes
        if (mediaItem.classification !== mediaItemClassification.MOVIE
            && mediaItem.classification !== mediaItemClassification.EPISODE) return null;

        // display SVOD CTA buttons for mediaItem that has no TVOD offers
        if (!hasTVODOffers) {
            return (
                <div className="vub-c-cta-button-bar-view">
                    {mediaItem.offers
                        .filter(offer => offer.offerType === mediaItemOfferType.SVOD)
                        .map(offer => {
                            const fastCheckoutSubscription = () => checkoutSubscription(offer);
                            const provider = providers.get(offer.provider);
                            const isUserSubscribed = isUserSignedIn && !!userAccount.subscriptions
                                .find(subscription => subscription.provider === offer.provider);
                            const startOfferPlayback = () => startMediaItemOfferPlayback(offer);

                            return !isUserSubscribed ? (
                                <CTAButton
                                    key={offer.id}
                                    onClick={fastCheckoutSubscription}
                                    isDisabled={isFastCheckoutInProgress}
                                >
                                    <span>
                                        Subscribe
                                    </span>
                                    {provider && (
                                        <img
                                            className="vub-c-cta-button__icon vub-u-margin-left-small"
                                            src={provider.serviceCTALogo}
                                            alt="cta-icon"
                                        />
                                    )}
                                </CTAButton>
                            ) : (
                                <Fragment key={offer.id}>
                                    <CTAButton onClick={startOfferPlayback}>
                                        <span>Watch SD on</span>
                                        {provider && (
                                            <img
                                                className="vub-c-cta-button__icon vub-u-margin-left-small"
                                                src={provider.serviceCTALogo}
                                                alt="cta-icon"
                                            />
                                        )}
                                    </CTAButton>
                                    <span className="vub-c-cta-button-bar-view__play-message">
                                        Included with your account
                                    </span>
                                </Fragment>
                            );
                        })}
                </div>
            );
        }

        // display TVOD CTA buttons for media that has at least one offer of TVOD type
        if (hasTVODOffers) {
            const entitlement = currentEntitlementsByMediaItem && currentEntitlementsByMediaItem.get(mediaItem.id);
            const purchasedOffer = entitlement ? mediaItem.offers
                .find(offer => offer.id === entitlement.offerId) : null;
            const startOfferPlayback = () => startMediaItemOfferPlayback(purchasedOffer);

            return !entitlement ? (
                <div className="vub-c-cta-button-bar-view">
                    {mediaItem.offers
                        .filter(offer => offer.offerType !== mediaItemOfferType.SVOD)
                        .map(offer => {
                            const fastCheckoutOffer = () => fastCheckoutMediaItemOffer(offer);
                            const provider = providers.get(offer.provider);
                            const price = offer.price.toLocaleString('en', {
                                style: 'currency',
                                currency: offer.currency,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            });

                            return (
                                <CTAButton
                                    key={offer.id}
                                    onClick={fastCheckoutOffer}
                                    isDisabled={isFastCheckoutInProgress}
                                >
                                    <span>
                                        Rent {offer.resolution} <span className="vub-c-cta-button__price">{price}</span>
                                    </span>
                                    {provider && (
                                        <img
                                            className="vub-c-cta-button__icon vub-u-margin-left-small"
                                            src={provider.serviceCTALogo}
                                            alt="cta-icon"
                                        />
                                    )}
                                </CTAButton>
                            );
                        })}
                </div>
            ) : (
                <div className="vub-c-cta-button-bar-view">
                    <Fragment key={purchasedOffer.id}>
                        <CTAButton onClick={startOfferPlayback}>
                            <span>
                                Watch {purchasedOffer.resolution} on
                            </span>
                            {providers.get(purchasedOffer.provider) && (
                                <img
                                    className="vub-c-cta-button__icon vub-u-margin-left-small"
                                    src={providers.get(purchasedOffer.provider).serviceCTALogo}
                                    alt="cta-icon"
                                />
                            )}
                        </CTAButton>
                        <div className="vub-c-cta-button-bar-view__play-message">
                            Playable until {entitlement.rentalExpiryTime
                                && entitlement.rentalExpiryTime.format('DD/MM/YYYY [at] HH:mm [(GMT)]')}
                        </div>
                    </Fragment>
                </div>
            );
        }

        // return null if no render condition is matched
        return null;
    }
}

export default CTAButtonBarView;
