/**
 * Route paths
 */
export default {
    STOREFRONT: '/',
    MEDIA_COLLECTION: '/collections/:mediaCollectionId/:classification?',
    MEDIA_GENRE: '/genres/:mediaGenreId/:classification?',
    MEDIA_ITEM: '/media-items/:mediaItemId',
    MEDIA_ITEM_MOVIE: '/movies/:mediaItemId',
    MEDIA_ITEM_TV_SHOW: '/tv-shows/:mediaItemId',
    MEDIA_ITEM_SEASON: '/tv-shows/:mediaItemId/:seasonMediaItemId',
    MEDIA_ITEM_EPISODE: '/tv-shows/:mediaItemId/:seasonMediaItemId/:episodeMediaItemId',
    MEDIA_ALL_MOVIES: '/all-movies',
    MEDIA_ALL_TV_SHOWS: '/all-tv-shows',
    MEDIA_SEARCH: '/search/:searchQueryString?',
    STYLE_GUIDE: '/style-guide',
    PAGE: '/pages/:pageId',
    SIGN_IN: '/sign-in',
    SIGN_UP: '/sign-up',
    SET_CONTACT_PREFERENCES: '/set-contact-preferences',
    PASSWORD_RECOVERY: '/request-password-reset',
    PASSWORD_RESET: '/reset-password',
    ACCOUNT_SETTINGS: '/account-settings',
    ACCOUNT_LIBRARY: '/my-library',
    OAUTH2_AUTHENTICATION: '/login',
    OIDC_AUTHENTICATION: '/oidc/authenticate',
};
