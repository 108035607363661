import {call, put, spawn, select} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import qs from 'qs';
import * as actionTypes from './authActionTypes';
import routePaths from '../routePaths';
import {authService} from '../services';
import * as selectors from './authSelectors';
import {END_SIDE_EFFECTS_RUNNING} from '../application/connectedRouterSagas';
import userSessionFlow from './sagas/userSessionFlow';
import {getOnceNewUserSessionRoute} from './sagas/userSessionRouteHandlers';

/**
 * Access control saga
 * TODO implement real role based access checking logic
 */
export const accessControl = function* accessControl({payload}) {
    const isUserSignedIn = yield select(selectors.getUserSignedInStatus);
    const {roles, redirectTo} = payload;
    if (!redirectTo) return;

    if (roles.indexOf('PRIVATE') > -1 && !isUserSignedIn) {
        yield put(push(redirectTo));

        return END_SIDE_EFFECTS_RUNNING;
    }

    if (roles.indexOf('ANONYMOUS') > -1 && isUserSignedIn) {
        yield put(push(redirectTo));

        return END_SIDE_EFFECTS_RUNNING;
    }
};

/**
 * Load user session
 */
export const loadUserSession = function* loadUserSession() {
    // if isUserSignedIn is set (boolean), then user session is loaded
    const isUserSignedIn = yield select(selectors.getUserSignedInStatus);
    if (typeof isUserSignedIn === 'boolean') return null;

    const authToken = yield call(authService.restoreUserSession);
    if (authToken) {
        const response = yield call(authService.getUserAccount);
        const {userAccount: userAccountDTO} = response;
        yield put({
            type: actionTypes.STORE_USER_ACCOUNT,
            payload: {userAccountDTO},
        });
        yield spawn(userSessionFlow, authToken);
    }
};

/**
 * Load auth form default state
 * TODO find better solution for storing form server errors
 */
export const loadAuthFormDefaultState = function* loadAuthFormDefaultState() {
    yield put({
        type: actionTypes.STORE_SERVER_ERRORS,
        payload: {serverErrors: []},
    });
};

/**
 * Load OAuth2 access token
 */
export const loadOAuth2AccessToken = function* loadOAuth2AccessToken({payload}) {
    const {location} = payload;
    const queryStringParams = qs.parse(location.search, {ignoreQueryPrefix: true});
    const accessToken = queryStringParams['access_token'];

    if (accessToken) {
        const response = yield call(authService.authenticateUser, {authToken: accessToken});
        const {userAccount: userAccountDTO, authToken} = response;
        yield put({
            type: actionTypes.STORE_USER_ACCOUNT,
            payload: {userAccountDTO},
        });
        yield put(push(getOnceNewUserSessionRoute() || routePaths.STOREFRONT));

        yield spawn(userSessionFlow, authToken);
    }
};

/**
 * Load OIDC access token
 */
export const loadOIDCAccessToken = function* loadOIDCAccessToken({payload}) {
    const {location} = payload;
    const locationHashParts = location.hash.split('&');
    const accessTokenPart = locationHashParts.find(locationHashPart => locationHashPart.includes('access_token'));
    const accessToken = accessTokenPart.replace('access_token=', '');

    if (accessToken) {
        const response = yield call(authService.authenticateUser, {authToken: accessToken});
        const {userAccount: userAccountDTO, authToken} = response;
        yield put({
            type: actionTypes.STORE_USER_ACCOUNT,
            payload: {userAccountDTO},
        });
        yield put(push(getOnceNewUserSessionRoute() || routePaths.STOREFRONT));

        yield spawn(userSessionFlow, authToken);
    }
};
